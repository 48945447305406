


@keyframes opacity-in {
    0% {
        opacity: 0.9;
        visibility: hidden;
		transform: translateY(-30px);
	
	
        
    }
    100% {
        opacity: 1;
        visibility: visible;
		 
		 transform: translateY(0);
		
		
        
    }
}

@keyframes opacity-out {
    0% {
        opacity: 1;
        visibility: visible;
		transform: translateY(30px);
		
        
    }
    100% {
        opacity: 0.9;
        visibility: hide;
		
		 transform: translateY(0);
		
        
    }
}


/* animation */
/*
==============================
BASE
==============================
*/

*[data-anima-out] .anima {
    opacity: 0;
}


/*
==============================
rotate 90
==============================
*/
@keyframes rotate-90 {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(90deg);
    }
}

.is-visible .rotate-90 {
    animation: rotate-90 .5s;
    transform: rotate(90deg);
    opacity: 1 !important;
}
/*
==============================
rotate 20
==============================
*/
@keyframes rotate-20 {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(20deg);
    }
}

.is-visible .rotate-20 {
    animation: rotate-20 .5s;
    transform: rotate(20deg) !important;
    opacity: 1 !important;
}
/*
==============================
SCALE UP
==============================
*/
@keyframes scale-up {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(1.2);
    }
}

.is-visible .scale-up, .is-visible .img-scale-up:hover img {
    animation: scale-up .5s;
    transform: scale(1.2) !important;
    opacity: 1;
}
/*
==============================
SCALE UP 2X
==============================
*/
@keyframes scale-up-2x {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(2);
    }
}

.is-visible .scale-up-2x, .is-visible .img-scale-up-2x:hover img {
    animation: scale-up-2x .5s;
    transform: scale(2);
    opacity: 1 !important;
}
/*
==============================
SCALE ROTATE
==============================
*/
@keyframes scale-rotate {
    0% {
        transform: scale(1) rotate(0);
    }

    100% {
        transform: scale(1.2) rotate(4deg);
    }
}

.is-visible .scale-rotate, .is-visible .img-scale-rotate:hover img {
    transform: scale(1.2) rotate(4deg);
    animation: scale-rotate .5s;
    opacity: 1;
}
/*
==============================
CIRCULAR ROTATION
==============================
*/
@keyframes spinAround {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.is-visible .circular-rotation {
    animation: spinAround 2s linear infinite;
    opacity: 1 !important;
}

/*
==============================
FADE
==============================
*/
@keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.is-visible .fade-in {
    animation: fadein .5s;
    opacity: 1 !important;
}
/*
==============================
FADE LEFT
==============================
*/
@keyframes fadeleft {
    0% {
        opacity: 0;
        transform: translateX(-100px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.is-visible .fade-left {
    animation: fadeleft .5s;
    opacity: 1 !important;
}


.fade-left-2  {
	opacity: 0;
}
.is-visible .fade-left-2 {
    animation-name: fadeleft;
    animation-duration: .5s;   
    animation-timing-function: ease-in-out;        
    visibility: visible !important;
	animation-fill-mode: forwards;
    animation-delay: 0.5s;
}

/*
==============================
FADE RIGHT
==============================
*/
@keyframes faderight {
    0% {
        opacity: 0;
        transform: translateX(100px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.is-visible .fade-right {
    animation: faderight .5s;
    opacity: 1 !important;
	
}

.fade-right-2  {
	opacity: 0;
}
.is-visible .fade-right-2 {
    animation-name: faderight;
    animation-duration: .5s;   
    animation-timing-function: ease-in-out;        
    visibility: visible !important;
	animation-fill-mode: forwards;
    animation-delay: 0.5s;
}

.fade-right-3  {
	opacity: 0;
}
.is-visible .fade-right-3 {
    animation-name: faderight;
    animation-duration: .5s;   
    animation-timing-function: ease-in-out;        
    visibility: visible !important;
	animation-fill-mode: forwards;
    animation-delay: 0.5s;
}

.fade-right-4  {
	opacity: 0;
}
.is-visible .fade-right-4 {
    animation-name: fadebottom;
    animation-duration: .5s;   
    animation-timing-function: ease-in-out;        
    visibility: visible !important;
	animation-fill-mode: forwards;
    animation-delay: 0.5s;
}


/*
==============================
FADE TOP
==============================
*/
@keyframes fadetop {
    0% {
        opacity: 0;
        transform: translateY(-100px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.is-visible .fade-top {
    animation: fadetop .5s;
    opacity: 1 !important;
}
/*
==============================
FADE BOTTOM
==============================
*/
@keyframes fadebottom {
    0% {
        opacity: 0;
        transform: translateY(100px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.is-visible .fade-bottom {
    animation: fadebottom .5s;
    opacity: 1 !important;
}
/*
==============================
SHOW SCALE
==============================
*/
@keyframes show-scale {
    0% {
        opacity: 0;
        transform: scale(0);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.is-visible .show-scale {
    animation: show-scale .5s;
    opacity: 1 !important;
}

/*
==============================
SLDIE RIGHT LEFT
==============================
*/
@keyframes fromRightToLeft {
    0% {
        opacity: 1;
        transform: translate(0);
    }

    25% {
        opacity: 0;
        transform: translate(+100%);
    }

    50% {
        transform: translate(-100%);
    }

    100% {
        opacity: 1;
        transform: translate(0);
    }
}

.is-visible .slide-right-left {
    animation: fromRightToLeft 0.5s forwards;
    opacity: 1 !important;
}

/*
==============================
SLIDE TOP BOTTOM
==============================
*/
@keyframes fromTopToBottom {
    0% {
        opacity: 1;
        transform: translate(0,0);
    }

    25% {
        opacity: 0;
        transform: translate(0,+100%);
    }

    50% {
        transform: translate(0,-100%);
    }

    100% {
        opacity: 1;
        transform: translate(0,0);
    }
}

.is-visible .slide-top-bottom {
    animation: fromTopToBottom 0.5s forwards;
    opacity: 1 !important;
}

/*
==============================
PULSE
==============================
*/

@keyframes pulse {
    25% {
        opacity: .8;
        transform: scale(1.1);
    }

    75% {
        opacity: 1;
        transform: scale(0.9);
    }
}

.pulse, .pulse-fast, .img-pulse:hover img, .img-pulse-fast:hover img {
    animation-name: pulse;
    animation-timing-function: linear;
    opacity: 1 !important;
}

.pulse, .img-pulse:hover img {
    animation-duration: 1s;
    animation-iteration-count: 2;
}

.pulse-fast, .img-pulse-fast:hover img {
    animation-duration: .5s;
    animation-iteration-count: 1;
}
/*
==============================
PULSE HORIZONTAL
==============================
*/

@keyframes pulse-horizontal {
    16.65% {
        transform: translateX(8px);
    }

    33.3% {
        opacity: 1;
        transform: translateX(-6px);
    }

    49.95% {
        transform: translateX(4px);
    }

    66.6% {
        transform: translateX(-2px);
    }

    83.25% {
        transform: translateX(1px);
    }

    100% {
        transform: translateX(0);
    }
}

.pulse-horizontal, .img-pulse-horizontal:hover img {
    animation-name: pulse-horizontal;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    opacity: 1 !important;
}
/*
==============================
PULSE VERTICAL
==============================
*/

@keyframes pulse-vertical {
    16.65% {
        transform: translateY(8px);
    }

    33.3% {
        opacity: 1;
        transform: translateY(-6px);
    }

    49.95% {
        transform: translateY(4px);
    }

    66.6% {
        transform: translateY(-2px);
    }

    83.25% {
        transform: translateY(1px);
    }

    100% {
        transform: translateY(0);
    }
}

.pulse-vertical, .img-pulse-vertical:hover img {
    animation-name: pulse-vertical;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    opacity: 1 !important;
}


/*
==============================
GRAPHICS ANIMATIONS - GLOBAL SETTINGS
==============================
*/

.ganimation-pulse:after, .ganimation-explode:after {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    content: '';
    box-sizing: content-box;
}

/*
============================== 
GANIMATION - PULSE
==============================
*/
@keyframes ganimation-pulse {
    0% {
        opacity: 1;
        transform: scale(1);
    }

    100% {
        transform: scale(2);
        opacity: 0;
    }
}

.ganimation-pulse:after {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    z-index: -1;
    background: rgba(90, 90, 90, 0.4);
    animation-name: ganimation-pulse;
    animation-duration: .3s;
    border-radius: 3px;
    opacity: 0;
}

.circle.ganimation-pulse, .circle.ganimation-explode {
    overflow: visible;
}

    .circle.ganimation-pulse:after, .circle.ganimation-explode:after {
        border-radius: 50%;
        overflow: visible;
    }

/*
============================== 
GANIMATION - EXPLODE
==============================
*/
@keyframes sonarEffect {
    0% {
        opacity: 0.3;
    }

    40% {
        opacity: 0.5;
        box-shadow: 0 0 20px 20px rgba(197, 197, 197, 0.2);
    }

    100% {
        box-shadow: 0 0 20px 20px rgba(197, 197, 197, 0.2);
        transform: scale(1.5);
        opacity: 0;
    }
}

.ganimation-explode {
    transition: transform ease-out 0.1s, background 0.2s;
    -webkit-transform: scale(0.93);
    -moz-transform: scale(0.93);
    -ms-transform: scale(0.93);
    transform: scale(0.93);
}

    .ganimation-explode:after {
        top: 0;
        left: 0;
        padding: 0;
        z-index: -1;
        border-radius: 3px;
        opacity: 0;
        -webkit-transform: scale(0.9);
        -moz-transform: scale(0.9);
        -ms-transform: scale(0.9);
        transform: scale(0.9);
        -webkit-animation: sonarEffect .3s ease-out 75ms;
        -moz-animation: sonarEffect .3s ease-out 75ms;
        animation: sonarEffect .3s ease-out 75ms;
        background-color: rgba(197, 197, 197, 0.2);
    }


/*
==============================
GANIMATION - GLASS
==============================
*/
@keyframes glass {
    0% {
        transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,45deg) translate3d(0,-100%,0);
        opacity: 1;
    }

    100% {
        transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,45deg) translate3d(0,100%,0);
        opacity: 1;
    }
}

.ganimation-glass {
    overflow: hidden;
}

    .ganimation-glass::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(155, 155, 155, 0.31);
        content: '';
        animation-name: glass;
        animation-duration: .4s;
        opacity: 0;
        z-index: 1;
    }
    
[data-anima] {
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
}



.is-visible .delay-1 {
	animation-delay: 1s ;
}

.delay-2 {
	opacity: 0 !important;
}

.is-visible .delay-2 {
	animation-delay: 2s ;
	opacity: 1 !important;
}


.is-visible .delay-3 {
	animation-delay: 3s ;
}


.is-visible .delay-4 {
	animation-delay: 4s ;
}