
.bg-blue {
    background-color: $blue;
}
.bg-indigo {
    background-color: $indigo;
}
.bg-purple {
    background-color: $purple;
}
.bg-pink {
    background-color: $pink;
}
.bg-red {
    background-color: $red;
}
.bg-orange {
    background-color: $orange;
}
.bg-yellow {
    background-color: $yellow;
}
.bg-green {
    background-color: $green;
}
.bg-teal {
    background-color: $teal;
}
.bg-cyan {
    background-color: $cyan;
}

