/*------------------------------------*\
  Slide principal
\*------------------------------------*/
.slide-principal {
    .slide {
        width: 100%;
        background-size: cover;
        background-position: center center;
        color: #fff;
        height: calc(100vh - 100px);
        min-height: 480px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .slide-text {
        text-shadow:
            1px 1px 0px rgba(0,0,0,.2),
            2px 2px 0px rgba(0,0,0,.4),
            3px 3px 0px rgba(0,0,0,.5);


    }


    .owl-nav .owl-prev {
        left: 15px;
    }

    .owl-nav .owl-next {
        right: 15px;
    }

    .owl-dots {
        position: absolute;
		bottom: 0.5rem;
		left: 0;
        right: 0;
        margin: 0;
    }

    .owl-item .slide .title {
        opacity: 0;
        transform: translate(50%, 0);
    }


    .owl-item.active .slide .title {
        opacity: 1;
        transform: translate(0, 0);
        transition: all 500ms ease 0.8s;
    }

    .owl-item .slide .animation-2 {
        opacity: 0;
        transform: translate(-100%, 0);
    }


    .owl-item.active .slide .animation-2 {
        opacity: 1;
        transform: translate(0, 0);
        transition: all 500ms ease 0.8s;
    }
}


.slide-rooms, .slide-servicios, .slide-actividades, .slide-fotos {
    margin: 0;
    clear: both;
    .owl-stage-outer {
        padding-bottom: 0.5rem;
        padding-top: 2rem;
    }
    .owl-stage {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        
    }
    .owl-item {
        display: flex;
        
    }

}
