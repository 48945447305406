


textarea.form-control {
    min-height: 100px !important;
    //border: 1px solid $primary;
}



// ctf7
.input-group-icon {
    display: flex;
    align-items: center;
    padding-left: 10px;

    span {
        font-size: 1.5rem;
        color: $primary;
    }
}

span.wpcf7-list-item {
    margin: 0 !important;
}

.wpcf7-list-item-label {
    padding-left: 25px;
}


.option-left {
    overflow: hidden;

    input[type=radio],
    input[type=checkbox] {
        display: none;
    }

    input[type=radio]+label,
    input[type=checkbox]+label {
        padding: 0 0 0 26px;
        margin: 0;
        display: inline-block;
        cursor: pointer;
        position: relative;
    }


    input[type=checkbox]+.wpcf7-list-item-label:before {
        content: '';
        border-radius: 0;
    }

    input[type=checkbox]:checked+.wpcf7-list-item-label:before {
        font-family: 'ElegantIcons';
        content: "\4e";
        background-color: $primary;
        border: 1px solid $primary;
        color: #fff;

    }



    input[type=radio]+label:before {
        content: '';
        border-radius: 50%;
        cursor: pointer;
    }

    input[type=radio]+label:before,
    input[type=checkbox]+label:before {
        font-size: 12px;
        line-height: 14px;
        color: #fff;
        text-align: center;
    }

    

    .wpcf7-list-item-label {
        display: inline-block;
        float: left;
        cursor: pointer;
        position: relative;
        margin-left: 0;
        padding-left: 20px;

        &:before {
            float: left;
            content: '';
            display: inline-block;
            width: 15px;
            height: 15px;
            margin: 0;
            line-height: 15px;
            background-color: $primary;
            border: 1px solid $white;
            outline: 1px solid $primary;
            position: absolute;
            top: 5px;
            left: 0;
            text-align: center;
            font-size: 12px;
        }
    }
}


.mc4wp-response {
    margin: 2rem auto;
    text-align: center;
}

.validate-option {
    overflow: hidden;

    input[type=radio],
    input[type=checkbox] {
        display: none;
    }

    input[type=radio]+label,
    input[type=checkbox]+label {
        padding: 0 0 0 26px;
        margin: 0;
        display: inline-block;
        cursor: pointer;
        position: relative;
    }

    input[type=checkbox]+label:before {
        content: '';
        border-radius: 0;
    }

    input[type=checkbox]:checked+label:before {
        font-family: 'ElegantIcons';
        content: "\4e";
        background-color: $primary;
        border: 1px solid $primary;
        color: $white;

    }

    input[type=radio]+label:before {
        content: '';
        border-radius: 50%;
        cursor: pointer;
    }

    input[type=radio]+label:before,
    input[type=checkbox]+label:before {
        font-size: 12px;
        line-height: 14px;
        color: #000;
        text-align: center;
    }
    

    label {
        display: inline-block;
        float: left;
        cursor: pointer;
        position: relative;



        &:before {
            float: left;
            content: '';
            display: inline-block;
            width: 15px;
            height: 15px;
            margin: 0;

            background-color: $white;
            border: 1px solid $gray-500;
            position: absolute;
            top: 5px;
            left: 0;
        }
    }
}
