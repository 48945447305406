/* menu-footer */
.footer {

    text-align: left;
    @include media-breakpoint-up(sm) {
        text-align: left;
    }

    .legal-footer {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
            display: inline;
            position: static;
            margin-top: 0;
            padding: 0 0 0 15px;
            a {
                &:hover, &:focus {
                    text-decoration: none;
                }
            }
        }
    }
}

.idiomas {
    display: flex;
    margin: 0;
    a, span {
        padding-right: 0.2rem;
    }
}


/* Cookie bar */

#cookie-law-info-bar {
    padding: 10px 0 !important;
    font-size: 18px !important;
    border-top: 0 !important;
    background-color: #f9f9f9 !important;
    position: fixed !important;
    box-shadow: none !important;
    text-align: left !important;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 700;
    
}

#cookie-law-info-bar p {
    margin: 10px 0;
    display: block;
    clear: both;
    
}



.cookies .cookies-close {
    padding: 0;
    margin: 0;
}

.cookie_action_close_header {
    padding: 10px 0;
    border: 0;
    color: #000 !important;
    background: transparent !important;
    margin: 0  !important;
    border-radius: 0 !important;
    text-decoration: underline !important;
    line-height: 30px;
    display: inline-block;
    cursor: pointer;
    font-size: 18px !important;
	@media (min-width: 350px) {
		font-size: 22px !important;
	}
	@media (min-width: 768px) {
		font-size: 22px !important;
	}
	@media (min-width: 992px) {
		font-size: 22px !important;
    }
    @media (min-width: 1200px) {
		font-size: 30px !important;
	}

}


.cookie_action_close_header:hover,
.cookie_action_close_header:focus {
    text-decoration: none;
    background: #fff !important;
    color: #000 !important;
}

#cookie-law-info-bar .cli-plugin-main-link {
    margin: 0;
}