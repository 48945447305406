
/* dark theme */
body {
    color: $black;
    background-color: $white;
    font-variant-ligatures: none;
    line-height: 1.6;
}


* { 
    -webkit-font-smoothing: antialiased; 
    -moz-osx-font-smoothing: grayscale; 

}


a {
    color: $black;
    &:hover, &:focus {
        color: $black;
        text-decoration: none;
  
    }
}

b, strong {
    font-weight: 500;
}


button:focus {outline:0;}


@media screen and (prefers-color-scheme: dark) {
    body {
        color: $white;
        background-color: $black;
    }
    a {
        color: $white;
        &:hover, &:focus {
            color: $white;
            text-decoration: none;
        }
    }

    
}


@include media-breakpoint-up(md) {
    .color-titulo-invertido {
        color: $white;
    }
    @media screen and (prefers-color-scheme: dark) {
        .color-titulo-invertido {
            color: $black;
        }
    }
}

/* content */
.content {
    overflow-x: hidden;
    padding-top: 100px;
    a {
        text-decoration: underline;
    }
}


.container, .container-fluid {
    padding-left: 20px;
    padding-right: 20px;

}


@media (min-width: 1400px) {
    .container {
        max-width: 1340px;
    }
}

@media (min-width: 1800px) {
    .container {
        max-width: 1740px;
    }
}


.mb-15 {
    margin-bottom: 20px;
}



.big-text {
    //font-size: 1rem;
    @include media-breakpoint-up(md) {
        //font-size: 1.333333rem;
    }
}

#view-project {
    display: none;
    position: absolute;
    z-index: 900;
    font-size: 1rem;
}



/* projects */
.front-page-project-list {
    margin-bottom: 5rem;
}

.link-home-principal {
    
    color: #000000;
    letter-spacing: 0.2px;
    padding-bottom: 5px;
    border-bottom: 1px solid #000;
    transition: all 0.3s ease;
    display: inline-block;
    position: relative;
    text-decoration: none !important;
    @media screen and (prefers-color-scheme: dark) {
        border-bottom: 1px solid #fff;
        color: #fff;
    }
    &:hover, &:focus {
        padding-bottom: 4px;
        border-bottom: 2px solid #000;
        @media screen and (prefers-color-scheme: dark) {
            border-bottom: 2px solid #fff;
        }
    }
    
}

.categorys a {
    //color: #000000;
    letter-spacing: 0.2px;
    padding-bottom: 5px;
    border-bottom: 1px solid #000;
    transition: all 0.3s ease;
    display: inline-block;
    position: relative;
    text-decoration: none !important;
    @media screen and (prefers-color-scheme: dark) {
        border-bottom: 1px solid #fff;
    }
    &:hover, &:focus {
        padding-bottom: 4px;
        border-bottom: 2px solid #000;
        @media screen and (prefers-color-scheme: dark) {
            border-bottom: 2px solid #fff;
        }
    }
}


.front-page-title {
    margin: .8em 0 .8em;
    //line-height: 1;
    font-family: $font-title;
    font-size: 2rem;
    line-height: 1.1;
    @include media-breakpoint-up(lg) {
        font-size: 2.5rem;
    }
    @media (min-width: 1600px) {
        font-size: 3rem;
    }
}

.languages-header {
    margin: 0 auto auto auto  !important;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: center;
    font-family: $font-title;
    font-size: 2rem;
    //line-height: 1.1;
    @include media-breakpoint-up(lg) {
        font-size: 3rem;
    }
    li {
        padding: 0 5px;
        position: relative;
        display: flex;
        a:first-letter {
         text-transform: uppercase;
        }
        &:after {
            content: '/';
            padding-left: 7px;
        }
        &:last-child {
            &:after {
                content: '';
                padding-left: 0;
            }
        }
        &.active a {
            opacity: 0.5;;
        }

    }

}

.front-page-text {
    margin: .8em 0 .8em;
}

.imagen-del-proyecto-home {
    img {
        max-width: 100%;
        height: auto;
    }
}
.bg-proyecto-home {
    width: 100%;
    height: 0;
    padding-bottom: 56%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    @include media-breakpoint-up(md) {
        padding-bottom: 56%;
    }
}



@keyframes fadeInUp {
	0% {
		opacity: 0;
		transform: translateY(50px);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}


.fadeInUp {
    opacity: 0;
    &.is-visible {
        opacity: 1;
        animation: fadeInUp 2s;
        //animation-delay: 1s ;
    }
}



.front-page-project-permalink {
    color: $black;
    text-decoration: none;
    display: block;
    text-decoration: none !important;
    margin-bottom: 0;
   
    @media screen and (prefers-color-scheme: dark) {
        color: $white;
    }
    
    &:hover, &:focus {
        color: $black;
        text-decoration: none;
        @media screen and (prefers-color-scheme: dark) {
            color: $white;
        }
        .hover-color {
            opacity: 0.7;
        }
    }
    .front-page-project {
        position: relative;
        margin-bottom: 10px;
        cursor: none;
        img {
            width: 100%;
            height: auto;
        }
        @include media-breakpoint-up(md) {
            margin-bottom: 0;
        }

    }
    .image-bg {

    }
    .hover-color {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        margin: 0;
        opacity: 0;
        mix-blend-mode: multiply;
        @include transition($transition-base);
    }
    .front-page-project-title {
        cursor: pointer;
        margin: 20px 0 60px 0;
        h3, h3 {
            font-weight: normal;
        }
        strong {
            font-weight: 500;
        }
        
    }

}

/* projects archive */
.archive-page-project-permalink {
    color: $black;
    text-decoration: none;
    display: block;
    text-decoration: none !important;
    margin-bottom: 20px;
    @media screen and (prefers-color-scheme: dark) {
        color: $white;
    }
    &:hover, &:focus {
        color: $black;
        text-decoration: none;
        @media screen and (prefers-color-scheme: dark) {
            color: $white;
        }
        .hover-color {
            opacity: 0.7;
        }
    }
    .archive-page-project {
        position: relative;

    }
    .image-bg {

    }
    .hover-color {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        margin: 0;
        opacity: 0;
        mix-blend-mode: multiply;
        @include transition($transition-base);
    }
    .archive-page-project-title {
        margin: 0;
    }
}

.share-icon {
    text-decoration: underline;
}


/* animations */
.wpb_start_animation.wpb_appear {
    transition: opacity 0.5s ease-in !important;
    animation: none !important;
    transform: scale(1);
    opacity: 1;
    filter: alpha(opacity=100)
}

.wpb_single_image .vc_figure, .wpb_single_image img, .wpb_single_image .vc_single_image-wrapper {
    width: 100%;
}

/* videos */
.video {
    width: 100%;
    height: auto;
    background-size: cover;
}

.projec {
    width: 100%;
    overflow: hidden;
}

.cover {
    width: 100%;
    height: 0;
    min-height: 0;
    padding-bottom: 56.6%;
    background-size: cover;
    background-position: center center;
    position: relative;
    background-color: #eee;
    
    overflow: hidden;
    a {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}

/* image 16:9 */
.image-bg {
    width: 100%;
    height: 0;
    min-height: 0;
    padding-bottom: 56.6%;
    background-size: cover;
    background-position: center center;
    position: relative;
    background-color: #eee;
}

/* logos */
.logos {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    img {
        max-height: 70px;
        width: auto;
    }
}

/* map */
.map-item {
    width: 100%;
    height: 500px;
}


/* error 404 */
.m-error404 {
    background: #e8dcd2 url('../images/playa-bg.jpg') center center no-repeat !important;
    background-size: cover !important;
}


.m-error-404 {
    text-align: center;
    width: 100%;
    height: 100vh;
    min-height: 100%;
    display: flex !important;
    align-items: center;
    justify-content: center;

}


.carousel-inner {
    height: 100%;
}

.carousel-indicators {
    bottom: 0;
}


.video-background {

    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: -99;
    height: 100vh;
}

.video-foreground,
.video-background iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

@media (min-aspect-ratio: 16/9) {
    .video-foreground {
        height: 300%;
        top: -100%;
    }
}


@media (max-aspect-ratio: 16/9) {
    .video-foreground {
        width: 300%;
        left: -100%;
    }
}



.error-404 {
    width: 100%;
    height: calc(100vh - 100px);
    background-color: $black;
    display: flex;
    align-items: center;
    @media screen and (prefers-color-scheme: dark) {
        color: $white;
    }
}

.error404 .footer {
    display: none;
}