/* Typography
-----------------------------------------------------------------------------*/
/*
html {
	font-size: 18px;
	@media (min-width: 350px) {
		font-size: 22px;
	}
	@media (min-width: 768px) {
		font-size: 22px;
	}
	@media (min-width: 992px) {
		font-size: 22px;
    }
    @media (min-width: 1200px) {
		font-size: 30px;
	}
	@media (min-width: 1500px) {
		font-size: 35px;
	}
}
*/

html {
    font-family: $font-text;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust:     100%;
}

html {
	font-size: 16px;
    //font-size: 62.5%;
    //background-color: #fff;
	@include media-breakpoint-up(md) {
		font-size: 18px;
	}
	@include media-breakpoint-up(xl) {
		font-size: 24px;
	}
}

